.Header {
  background-color: var(--white);
  height          : 80px;
  display         : flex;
  justify-content : space-between;
  position        : fixed;
  z-index         : 1;
  width           : 100vw;
}

.logo {
  width : 45px;
  height: 45px;
  border-radius: 10px;
}

.wrapper__header {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  padding-left   : 100px;
  padding-right  : 100px;
  padding-top    : 18px;
  padding-bottom : 17px;
  width          : 100%;
  gap            : 30px;
}

.search-input {
  width              : 388px;
  border             : none;
  background-color   : var(--gray);
  height             : 45px;
  border-radius      : 15px;
  padding-left       : 17px;
  background-image   : url(../images/search.svg);
  background-repeat  : no-repeat;
  background-position: 95% 50%;
  padding-right      : 17px;
  margin-left        : auto;
}

.search-input:focus {
  outline: none;
}

.search-button {
  background-color: #FFF;
  width           : 30px;
  height          : 30px;
  border-radius   : 10px;
  margin-right    : 17px;
}

.search-button>img {
  color: blue !important;
}

.network {
  display: flex;
  gap    : 15px;
}

.network img {
  width     : 30px;
  height    : 30px;
  margin-top: 6px;
}

/* ========================== RESPONSIVE mobile =============== */

@media only screen and (max-width: 780px) {
  .Header {
    height     : 60px !important;
    align-items: center;
  }

  .logo {
    width : 40px;
    height: 40px;
  }

  .wrapper__header {
    padding: 15px 20px;
    width  : 100vw;
  }

  .search-input {
    margin: auto;
  }

}

@media only screen and (max-width: 450px) {
  .search-input {
    width: 50vw;
  }

  .search-input:focus {
    position  : absolute;
    width     : 95vw;
    transition: width 0.5s ease;
    margin    : auto;
    left      : 0;
    right     : 0;
  }
}