.Categories {
  display        : flex;
  flex-wrap      : wrap;
  justify-content: center;
  max-width      : 938px;
  gap            : 20px;
  padding-left: 23px;
}

@media only screen and (max-width: 780px) {
  .Categories {
    justify-content   : space-between;
    flex-wrap         : nowrap;
    overflow          : scroll;
    padding-right     : 20px;
    width             : 100%;
    white-space       : nowrap;
    -ms-overflow-style: none;
    scrollbar-width   : none;
  }

  .Categories::-webkit-scrollbar {
    display: none;
  }
}