.Content {
  background         : rgba(255, 255, 255, 0.25);
  /* background-color: aqua; */
  position           : absolute;
  top                : 130px;
  left               : 0;
  right              : 0;
  border-radius      : 30px;
  margin             : auto;
  width              : 90%;
  max-width          : 1240px;
  padding            : 41px;
  display            : flex;
  flex-direction     : column;
  align-items        : center;
  justify-content    : center;
}

.Content>h1 {
  margin-bottom: 32px;
  font-weight  : 600;
  font-size    : 40px;
}

.Content>h2 {
  margin-bottom: 32px;
  font-weight  : 400;
  font-size    : 22px;
}

.created__by {
  display      : flex;
  margin       : auto;
  margin-bottom: 32px;
}

.created__by>span {
  margin-top: 12px;
}

.button {
  background-color: var(--white);
  border-radius   : 15px;
  height          : 45px;
  padding         : 7px 17px;
  margin-left     : 20px;
  color           : var(--blue);
  text-decoration : none;
  text-align      : center;
}

.button>span {
  line-height: 30px;
  font-weight: 600;
  font-size  : 22px;
}

/* =============== RESPONSIVE mobile =============== */

@media only screen and (max-width: 780px) {
  .Content {
    background   : none;
    width        : 100%;
    padding-right: 0;
    padding-left: 0;
    text-align   : center;
  }

  .Content>h1 {
    font-size: 30px;
  }

  .Content>h2 {
    font-size    : 15px;
    margin-right: 20px;
    margin-bottom: 16px;
    margin-left: 23px;
  }

  .button {
    width     : 155px !important;
    height    : 39px !important;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .button>span {
    font-size: 18px;
  }

  .created__by {
    margin-bottom: 32px;
  }

  .wrapper-categories {
    width  : 100%;
    padding: 0;
  }
}