:root {
--white: #FFFFFF;
--black: #090A0AE5;
--gray: #F9F9F9;
--blue: #00A2F5;
--hover: rgba(0, 0, 0, 0.03);
--gray-text: #5A6773;
}
@font-face {
    font-family: 'WorkSans';
    font-weight: 400;
    src: local('WorkSans'), url(./fonts/WorkSans-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'WorkSans';
    font-weight: 600;
    src: local('WorkSans'), url(./fonts/WorkSans-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'WorkSans';
    font-weight: 800;
    src: local('WorkSans'), url(./fonts/WorkSans-Bold.ttf) format('opentype');
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: WorkSans;
  font-weight: 400;
}

body {
  margin: 0;
  background-image: url(./images/Background.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1000px) {
  body {
    background-image: url(./images/Background-mobile.png);
  background-size: cover;
  background-repeat: no-repeat;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
