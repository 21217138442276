.thread {
  background-color: var(--white);
  border-radius   : 30px;
  overflow        : hidden;
  padding         : 20px 25px;
  border          : 1px solid #F0F0F0;
  text-decoration : none;
  width           : calc(100% - 2em);
  margin          : auto;
  margin-top      : 0;
}

.thread:hover {
  background-color: var(--hover);
  transition      : background-color 0.5s ease;
}

.thread__wrapper {
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  height         : 100%;
}

.thread__header {
  display        : flex;
  justify-content: space-between;
  margin-bottom  : 15px;
}

.thread__header>div {
  display: flex;
}

.thread__profile {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  margin-top     : 5px;
  margin-left    : 10px;
  color          : var(--black) !important;
}

.thread__profile span:first-of-type {
  /* color: red; */
  font-weight: 800;
}

.thread__profile span:last-of-type {
  color: var(--gray-text);
}

.logo-thread {
  width        : 45px;
  height       : 45px;
  border-radius: 50%;
}

.thread__text {
  text-align   : left;
  font-weight  : 600;
  margin-bottom: 15px;
  color        : var(--black) !important;
  white-space  : pre-line;
  text-overflow: ellipsis;
  overflow     : hidden;
}

.created_at {
  text-align: justify;
  color     : var(--gray-text);
}