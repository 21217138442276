.category {
  background-color: var(--black);
  color           : #FFF;
  padding-top     : 6px;
  padding-left    : 20px;
  padding-right   : 20px;
  padding-bottom  : 8px;
  border-radius   : 12px;
  box-sizing      : border-box;
  display         : inline-block;
  text-align      : center;
  border          : none;
  cursor          : pointer;
}

.selected {
  background-color: var(--blue) !important;
}

@media only screen and (max-width: 450px) {
  .category {
    height     : 34px;
    white-space: nowrap;
    font-size: 15px;
  }
}